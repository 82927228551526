<template>
  <div id="footerComponent">
    <div class="footerComponent">
      <div class="footerComponent-topPart">
        <div class="footerComponent-topPart-title">友情链接</div>
        <div class="footerComponent-topPart-labelList clear">
          <div @click="toGoWeb(3)" class="labelList-li">广东省政务服务网</div>
          <div @click="toGoWeb(4)" class="labelList-li">粤财扶助</div>
          <div @click="toGoWeb(5)" class="labelList-li">广州市政务服务</div>
          <div @click="toGoWeb(6)" class="labelList-li">广州市数字化企业服务平台</div>
          <div @click="toGoWeb(7)" class="labelList-li">广州市天河区人民政府</div>
          <div @click="toGoWeb(8)" class="labelList-li">广州市小额贷款行业协会</div>
        </div>
<!--        <div class="footerComponent-topPart-menu">-->
<!--          <div class="menu-li">关于我们</div>-->
<!--          <div class="menu-li">联系我们</div>-->
<!--          <div class="menu-li">意见反馈</div>-->
<!--          <div class="menu-li">我要投诉</div>-->
<!--          <div class="menu-li">帮助中心</div>-->
<!--        </div>-->
      </div>
      <div class="footerComponent-belowPart clear">
        <div class="footerComponent-belowPart-left">
          <div class="left-word">主办单位：广州市天河中央商务区管理委员会</div>
          <div class="left-word">版权所有：广州市天河中央商务区管理委员会</div>
        </div>
        <div class="footerComponent-belowPart-right">
<!--          <div class="right-li">关于我们</div>-->
<!--          <div class="right-li">联系我们</div>-->
<!--          <div class="right-li">意见反馈</div>-->
<!--          <div class="right-li">我要投诉</div>-->
<!--          <div class="right-li">帮助中心</div>-->
          <div class="right-tip">
            <div class="right-tip-li">联系我们</div>
            <div class="right-tip-li">12345</div>
          </div>
          <img @click="toGoWeb(1)" class="right-img1" src="../../assets/footer/jiucuo.png">
          <img @click="toGoWeb(2)" class="right-img2" src="../../assets/footer/dzjg.png">
        </div>
      </div>
      <div class="footerComponent-copyright">Copyright © 2023   版权所有   粤ICP备000000000号</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'footerComponent',
  data(){
    return {

    }
  },
  methods: {
    toGoWeb(type){
      if(type==1){
        window.open('https://zfwzgl.www.gov.cn/exposure/jiucuo.html?site_code=bm13000003&url=https%3A%2F%2Fgd.12348.gov.cn%2F', '_blank');
      }else if(type==2){
        window.open('http://bszs.conac.cn/sitename?method=show&id=6D01D851FD1B5E72E053022E1AAC08D2', '_blank');
      }else if(type==3){
        window.open('https://www.gdzwfw.gov.cn/', '_blank');
      }else if(type==4){
        window.open('https://czbt.czt.gd.gov.cn/#/home', '_blank');
      }else if(type==5){
        window.open('https://www.gdzwfw.gov.cn/?region=440100', '_blank');
      }else if(type==6){
        window.open('https://qyfw.gzonline.gov.cn/qyfw/home', '_blank');
      }else if(type==7){
        window.open('http://www.thnet.gov.cn/', '_blank');
      }else if(type==8){
        window.open('http://gzxdxh.org.cn/index.php?m=content&c=index&a=lists&catid=47', '_blank');
      }
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
@import "./index.scss";
</style>